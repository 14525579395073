import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Store the event listener so we can remove it later
    this.assignFrcHandler = (event) => {
      const { detail } = event
      this.addFrcAssignment(
        detail.frcTypeId,
        detail.siteId,
        detail.siteName,
        detail.frcTypeName,
        detail.siteState
      )
    }
    
    document.addEventListener("sites--search:assignFrc", this.assignFrcHandler)
  }

  disconnect() {
    // Clean up the event listener when the controller disconnects
    document.removeEventListener("sites--search:assignFrc", this.assignFrcHandler)
  }

  addFrcAssignment(frcTypeId, siteId, siteName, frcTypeName, siteState) {
    const hiddenFrc = document.querySelector(".frc__item.hidden")
    if (!hiddenFrc) return // Guard against missing template
    
    const frcContainer = hiddenFrc.parentElement
    const newHiddenFrc = hiddenFrc.cloneNode(true)
    frcContainer.append(newHiddenFrc)

    const lastIndexField = document.querySelector(".frc__last-index")
    if (!lastIndexField) return // Guard against missing index field

    const lastIndex = parseInt(lastIndexField.value, 10) || -1
    const newFieldIndex = lastIndex + 1

    lastIndexField.value = newFieldIndex

    hiddenFrc.querySelector(".frc__name").textContent = siteName
    hiddenFrc.querySelector(".frc__state").textContent = siteState
    hiddenFrc.querySelector(".frc__type").textContent = frcTypeName
    hiddenFrc
      .querySelector(".frc__destroy")
      .setAttribute(
        "name",
        `user[frcs_attributes][${newFieldIndex}][_destroy]`
      )
    const siteIdField = hiddenFrc.querySelector(".frc__site-id")
    siteIdField.setAttribute("value", siteId)
    siteIdField.setAttribute(
      "name",
      `user[frcs_attributes][${newFieldIndex}][site_id]`
    )

    const typeIdField = hiddenFrc.querySelector(".frc__type-id")
    typeIdField.setAttribute("value", frcTypeId)
    typeIdField.setAttribute(
      "name",
      `user[frcs_attributes][${newFieldIndex}][frctype_id]`
    )

    hiddenFrc.classList.remove("hidden")
  }

  toggleDelete(e) {
    const checkbox = e.target
    const isChecked = checkbox.checked

    const destroyField = checkbox.parentElement.querySelector(".frc__destroy")
    if (isChecked) {
      destroyField.setAttribute("value", "")
    } else {
      destroyField.setAttribute("value", "true")
    }
  }
}