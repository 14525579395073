import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["idField", "siteSearchModal", "siteTransferButton"]

  connect() {
    // Check if the targets are available, and assign if so
    this.idField = this.hasIdFieldTarget ? this.idFieldTarget : document.getElementById("new_site_id");
    this.siteTransferButton = this.hasSiteTransferButtonTarget ? this.siteTransferButtonTarget : document.getElementById("site_transfer_button");

    // Add keyup event to enable/disable button based on input validation
    if (this.idField && this.siteTransferButton) {
      this.idField.addEventListener("keyup", this.handleInputKeyUp.bind(this));
    }

    // Listen for custom event for site selection
    document.addEventListener("sites--search:select", this.populateSiteId.bind(this));

    console.log("Connected: sites--transfer controller");
  }

  disconnect() {
    document.removeEventListener("sites--search:select", this.populateSiteId.bind(this));
    if (this.idField) {
      this.idField.removeEventListener("keyup", this.handleInputKeyUp.bind(this));
    }
  }

  handleInputKeyUp(event) {
    // Enable the button if the input is numeric, disable otherwise
    this.siteTransferButton.disabled = !/^\d+$/.test(event.target.value);
  }

  populateSiteId(event) {
    const { siteId } = event.detail;
    if (this.idField) this.idField.value = siteId;
    this.hideModal();
    if (this.siteTransferButton) this.siteTransferButton.disabled = false;
  }

  hideModal(event) {
    if (event) event.preventDefault();
    if (this.siteSearchModal) {
      this.siteSearchModal.style.display = "none";
    }
  }
}
