// app/javascript/controllers/search_modal_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  selectedFrcType = {}
  selectedUstType = {}

  showFrcModal(event) {
    event.preventDefault()
    document.getElementById("frc_sites_search").style.display = "block"
  }

  showUstModal(event) {
    event.preventDefault()
    document.getElementById("ust_sites_search").style.display = "block"
  }

  showTransferModal(event) {
    event.preventDefault()
    document.getElementById("sites_search").style.display = "block"
  }

  showUploadModal(event) {
    event.preventDefault()
    document.getElementById("dialog").style.display = "block"
  }

  hideModal(event) {
    event.preventDefault()
    event.target.closest('.jqmWindow').style.display = "none"
  }

  updateFrcType(event) {
    const select = event.target
    const row = select.closest('tr')
    const siteId = row.getAttribute('id').replace('site_', '')
    this.selectedFrcType[siteId] = {
      id: select.value,
      name: select.options[select.selectedIndex].text
    }
  }

  updateUstType(event) {
    const select = event.target
    const row = select.closest('tr')
    const siteId = row.getAttribute('id').replace('site_', '')
    this.selectedUstType[siteId] = {
      id: select.value,
      name: select.options[select.selectedIndex].text
    }
  }

  addFrc(event) {
    event.preventDefault()
    const link = event.currentTarget
    const siteId = link.dataset.siteId
    const name = link.dataset.name
    const city = link.dataset.city
    const state = link.dataset.state
    const row = link.closest('tr')
    const select = row.querySelector('select')
    const frcTypeId = select.value
    const frcTypeName = select.options[select.selectedIndex].text

    const detail = {
      frcTypeId: frcTypeId,
      siteId: siteId,
      siteName: [name, city, state].join(', '),
      frcTypeName: frcTypeName,
      siteState: state
    }

    document.dispatchEvent(
      new CustomEvent('sites--search:assignFrc', { detail })
    )

    this.hideModal(event)
  }

  addUst(event) {
    event.preventDefault()
    const link = event.currentTarget
    const siteId = link.dataset.siteId
    const name = link.dataset.name
    const city = link.dataset.city
    const state = link.dataset.state
    const row = link.closest('tr')
    const select = row.querySelector('select')
    const ustId = select.value
    const ustName = select.options[select.selectedIndex].text

    const detail = {
      ustId: ustId,
      siteId: siteId,
      siteName: [name, city, state].join(', '),
      ustName: ustName,
      siteState: state
    }

    document.dispatchEvent(
      new CustomEvent('sites--search:assignUst', { detail })
    )

    this.hideModal(event)
  }
}