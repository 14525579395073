import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["results"]
  static values = {
    type: String
  }

  connect() {
    // Optional initialization
  }

  show(event) {
    console.log("show method called", this.typeValue) // Add this line
    event.preventDefault()
    const modal = document.getElementById(`${this.typeValue}models_search`)
    console.log("modal element:", modal) // Add this line
    modal.style.display = 'block'
  }

  select(event) {
    event.preventDefault()
    
    const modelId = event.currentTarget.dataset.modelSelectorIdParam
    const modelName = event.currentTarget.dataset.modelSelectorNameParam
    const type = this.typeValue
    
    // Update the form
    document.getElementById(`${type}model_name`).textContent = modelName
    document.querySelector(`#${type}model_info input`).value = modelId
    
    // Highlight the change
    const nameElement = document.getElementById(`${type}model_name`)
    nameElement.classList.add('highlight-fade')
    
    // Hide the modal
    this.close()
  }

  close(event) {
    event?.preventDefault()
    document.getElementById(`${this.typeValue}models_search`).style.display = 'none'
  }
}