import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["registrationFields", "registrationRequired"]

  connect() {
    // Hide or show the registration fields based on the initial state
    this.toggleRegistrationFields();
  }

  toggleRegistrationFields() {
    // Check if the "Yes" radio button is selected
    const isRegistrationRequired = this.registrationRequiredTarget.checked;
    if (isRegistrationRequired) {
      this.registrationFieldsTarget.style.display = "block";
    } else {
      this.registrationFieldsTarget.style.display = "none";
    }
  }

  showRegistrationFields() {
    this.registrationFieldsTarget.style.display = "block";
  }

  hideRegistrationFields() {
    this.registrationFieldsTarget.style.display = "none";
  }
}
