import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Store the event listener so we can remove it later
    this.assignUstHandler = (event) => {
      const { detail } = event
      this.addUstAssignment(
        detail.ustId,
        detail.siteId,
        detail.siteName,
        detail.ustName,
        detail.siteState
      )
    }
    
    document.addEventListener("sites--search:assignUst", this.assignUstHandler)
  }

  disconnect() {
    // Clean up the event listener when the controller disconnects
    document.removeEventListener("sites--search:assignUst", this.assignUstHandler)
  }

  addUstAssignment(ustId, siteId, siteName, ustName, siteState) {
    const hiddenUst = document.querySelector(".ust__item.hidden")
    if (!hiddenUst) return // Guard against missing template
    
    const ustContainer = hiddenUst.parentElement
    const newHiddenUst = hiddenUst.cloneNode(true)
    ustContainer.append(newHiddenUst)

    const lastIndexField = document.querySelector(".ust__last-index")
    if (!lastIndexField) return // Guard against missing index field

    const lastIndex = parseInt(lastIndexField.value, 10) || -1
    const newFieldIndex = lastIndex + 1

    lastIndexField.value = newFieldIndex

    hiddenUst.querySelector(".ust__name").textContent = siteName
    hiddenUst.querySelector(".ust__state").textContent = siteState
    hiddenUst.querySelector(".ust__type").textContent = ustName
    hiddenUst
      .querySelector(".ust__destroy")
      .setAttribute(
        "name",
        `user[ust_certified_operators_attributes][${newFieldIndex}][_destroy]`
      )
    const siteIdField = hiddenUst.querySelector(".ust__site-id")
    siteIdField.setAttribute("value", siteId)
    siteIdField.setAttribute(
      "name",
      `user[ust_certified_operators_attributes][${newFieldIndex}][site_id]`
    )

    const typeIdField = hiddenUst.querySelector(".ust__type-id")
    typeIdField.setAttribute("value", ustId)
    typeIdField.setAttribute(
      "name",
      `user[ust_certified_operators_attributes][${newFieldIndex}][ust_certified_operator_classification_id]`
    )

    hiddenUst.classList.remove("hidden")
  }

  toggleDelete(e) {
    const checkbox = e.target
    const isChecked = checkbox.checked

    const destroyField = checkbox.parentElement.querySelector(".ust__destroy")
    if (isChecked) {
      destroyField.setAttribute("value", "")
    } else {
      destroyField.setAttribute("value", "true")
    }
  }
}