// app/javascript/controllers/dms_converter_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "degrees", 
    "minutes", 
    "seconds", 
    "decimal"
  ]

  connect() {
    // Optional: Convert decimal to DMS on initial load if decimal value exists
    if (this.decimalTarget.value) {
      this.convertDecimalToDMS()
    }
  }

  convertDMSToDecimal() {
    if (!this.hasAllDMSValues()) return
    
    const seconds = parseFloat(this.secondsTarget.value) || 0
    const minutes = parseFloat(this.minutesTarget.value) || 0
    const degrees = parseFloat(this.degreesTarget.value) || 0

    const temp1 = seconds / 60
    const temp2 = temp1 + minutes
    const temp3 = temp2 / 60
    const decimalValue = (degrees + temp3).toFixed(6)

    this.decimalTarget.value = decimalValue
  }

  hasAllDMSValues() {
    return this.degreesTarget.value || 
           this.minutesTarget.value || 
           this.secondsTarget.value
  }

  convertDecimalToDMS() {
    const decimal = Math.abs(parseFloat(this.decimalTarget.value))
    if (isNaN(decimal)) return

    const degrees = Math.floor(decimal)
    const minutesDecimal = (decimal - degrees) * 60
    const minutes = Math.floor(minutesDecimal)
    const seconds = ((minutesDecimal - minutes) * 60).toFixed(2)

    this.degreesTarget.value = degrees
    this.minutesTarget.value = minutes
    this.secondsTarget.value = seconds
  }
}